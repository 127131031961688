import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import CategoryCard from "../components/CategoryCard";
import FullWidthImage from "../components/FullWidthImage";
import Content, { HTMLContent } from "../components/Content";
// eslint-disable-next-line
export const ProductPageTemplate = ({
  content,
  contentComponent,
  image,
  title,
  heading,
  intro,
}) => {
  const heroImage = getImage(image) || image;
  const PostContent = contentComponent || Content;

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">  
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-12">
                <h3 className="has-text-weight-semibold is-size-2">
                  {heading}
                </h3>
                <PostContent content={content} />
              </div>
            </div>
            <CategoryCard gridItems={intro.blurbs} /> 
          </div>
        </div>
      </section>
    </div>
  );
};

ProductPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
 /* main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),*/
};

const ProductPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = post;

  return (
    <Layout>
      <ProductPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        intro {
          blurbs {
            key
            image {
              childImageSharp {
                gatsbyImageData(height:240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
        }
      }  
    }
  }
`;
