import * as React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { navigate } from 'gatsby'

const navigateToProduct = (key) => {
    navigate(`/products/browse?category=${key}`);
}
const CategoryCard = ({ gridItems }) => (
  <div className="columns is-multiline" style={{}}>
    {gridItems.map((item) => (
      <div key={item.text} class="column is-4 card-container"
      onClick={()=>navigateToProduct(item.key)}>
        <div class="card">
            <div class="card-image" style={{height : item.image.childImageSharp.gatsbyImageData.height}}>
              <figure class="image">
                <PreviewCompatibleImage imageInfo={{
                  image : item.image,
                  alt : `image for ${item.key}`,
                }} />
              </figure>
            </div>
          <div class="card-content" style={{backgroundColor:"#aaaaaa", marginTop:"5px", height:"120px"}}>
              <p class="title is-4">{item.text}</p>
              <p class="subtitle is-6">LEARN MORE</p>
          </div>
        </div>
      </div>
    ))}
  </div>
);

CategoryCard.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default CategoryCard;
